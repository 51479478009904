<template>
  <div class="shop-goods-list-page">
    <en-table-layout ref="tableLayout" :tableData="pageData.data" :loading="loading" :search="+activeName != 3"
      @search="search" @selection-change="handleSelectionChange" @sort-change="handleSortChange">
      <template slot="header">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane v-for="(item, index) in navList" :key="index" :label="item.navigation_name"
            :name="item.navigation_id" :disabled="loading"></el-tab-pane>
        </el-tabs>
      </template>

      <!-- 企业跳站点的筛选条件 且 不是礼包 -->
      <template v-if="MixinIsFormEnterprise && +activeName != 3">
        <div slot="toolbar" class="row no-gutters align-items-center w-100">
          <div class="col">
            <el-form-item label="毛利率(市价)">
              <el-input-number style="width: 110px" size="small" v-model="params.profit_min" placeholder="最低毛利率" />
              <span style="margin: 0 5px">-</span>
              <el-input-number style="width: 110px" size="small" v-model="params.profit_max" placeholder="最高毛利率" />
            </el-form-item>
          </div>
          <div class="col-auto">
            <el-input style="width: 315px" size="medium" placeholder="输入商品名称" v-model="keyword" clearable>
              <el-select slot="prepend" v-model="key_word" style="width: 120px">
                <el-option value="goods_name" label="商品名称"></el-option>
                <el-option value="goods_sn" label="商品编号"></el-option>
                <el-option v-if="['0', '4'].includes(activeName) && $store.getters.isSupplierName" value="supplier_name"
                  label="供应商名称"></el-option>
              </el-select>
            </el-input>
          </div>
          <div class="col-auto ml-2">
            <el-button @click="search" size="small" type="primary">
              搜索
            </el-button>
          </div>
        </div>
        <template slot="toolbar_btn">
          <el-button @click="addGoods" v-if="MixinIsFormEnterprise" type="primary" size="small">添加商品
          </el-button>
          <el-button size="small" type="primary" @click="handleBatchCommand('modify')">批量改价
          </el-button>
          <span style="margin: 0 10px" v-if="hasReviseTask">改价任务进行中...</span>
          <el-button size="small" type="primary" @click="handleBatchCommand('on')">批量上架
          </el-button>
          <el-button size="small" type="primary" @click="handleBatchCommand('off')">批量下架
          </el-button>
          <el-button size="small" type="primary" @click="handleBatchCommand('remove')">批量移除
          </el-button>
          <el-button size="small" @click="exportShop" type="primary" :loading="importLoading">导出商品
          </el-button>
          <el-button v-if="activeName !== '2'" size="small" type="primary" @click="handleBatchCommand('clear')">下架全部无货商品
          </el-button>
        </template>
        <el-form slot="search">
          <el-row>
            <el-col :span="7"><el-form-item label="会员价" label-width="100px" v-if="MixinIsFormEnterprise">
                <el-input-number style="width: 100px" size="small" v-model="params.low_price" placeholder="最低会员价" />
                <span style="margin: 0 5px">-</span>
                <el-input-number style="width: 100px" size="small" v-model="params.high_price" placeholder="最高会员价" />
              </el-form-item></el-col>
            <el-col :span="7"><el-form-item label="市场价" label-width="100px">
                <el-input-number style="width: 100px" size="small" v-model="params.low_mktprice" placeholder="最低市场价" />
                <span style="margin: 0 5px">-</span>
                <el-input-number style="width: 100px" size="small" v-model="params.high_mktprice" placeholder="最高市场价" />
              </el-form-item></el-col>
            <el-col :span="10">
              <el-form-item label="价格关系" label-width="100px">
                <el-select style="width: 100px" size="small" class="choose-machine center" v-model="params.pre"
                  placeholder="选择价格" clearable>
                  <el-option v-for="item in list1" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
                <el-select style="width: 100px" size="small" class="choose-machine ml-2 center" v-model="params.sign"
                  placeholder="计算方式" clearable>
                  <el-option v-for="item in list2" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
                <el-select style="width: 100px" size="small" class="choose-machine ml-2 center" v-model="params.suf"
                  placeholder="选择价格" clearable>
                  <el-option v-for="item in list3" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item label="商品分类" label-width="100px">
                <el-cascader clearable style="width: 320px" v-model="categoryParams" :options="categoryProxy.children"
                  :props="categoryProxy.props" placeholder="请选择商品分类" class="cas-select" />
              </el-form-item>
            </el-col>
            <el-col :span="5"><el-form-item label="商品状态" label-width="100px">
                <el-select size="small" class="choose-machine center" v-model="params.shop_goods_status"
                  placeholder="请选择" clearable style="width: 100px">
                  <el-option label="全部" value=""></el-option>
                  <el-option v-for="item in goodsStatusList" :key="item.value" :label="item.label"
                    :value="item.value"></el-option>
                </el-select> </el-form-item></el-col>
            <el-col :span="9"><el-form-item v-if="activeName !== '2'" label="库存状态" label-width="100px">
                <el-select size="small" class="choose-machine center" v-model="params.has_goods" placeholder="请选择"
                  style="width: 100px">
                  <el-option label="全部" :value="0"></el-option>
                  <el-option label="有货" :value="1"></el-option>
                </el-select> </el-form-item></el-col>
            <el-col :span="10"><el-form-item v-if="activeName === '2'" label="商品配送类型" label-width="100px">
                <el-select style="width: 320px" v-model="params.ship_method" placeholder="请选择配送类型" clearable>
                  <el-option label="全部" value="" />
                  <el-option label="由厂家配送" :value="2" />
                  <el-option label="京东自营配送" :value="1" />
                </el-select> </el-form-item></el-col>
          </el-row>
          <el-row>
            <el-col :span="14"><el-form-item v-if="activeName === '2'" label="地区" label-width="100px">
                <el-select size="small" class="choose-machine center" v-model="areaSelectData.province"
                  placeholder="省/直辖市" style="width: 100px; margin-right: 5px" clearable>
                  <el-option v-for="(item, index) in this.provinceList" :label="item.name" :value="item.id"
                    :key="index">
                  </el-option>
                </el-select>
                <el-select v-if="areaNum > 1" size="small" class="choose-machine center" v-model="areaSelectData.city"
                  placeholder="城市/地区" style="width: 100px; margin-right: 5px" clearable>
                  <el-option v-for="(item, index) in this.cityList" :label="item.name" :value="item.id" :key="index">
                  </el-option>
                </el-select>
                <el-select v-if="areaNum > 2" size="small" class="choose-machine center" v-model="areaSelectData.county"
                  placeholder="县/区" style="width: 100px; margin-right: 5px" clearable>
                  <el-option v-for="(item, index) in this.countyList" :label="item.name" :value="item.id" :key="index">
                  </el-option>
                </el-select>
                <el-select v-if="areaNum > 3" size="small" class="choose-machine center" v-model="areaSelectData.town"
                  placeholder="乡镇/街道" style="width: 100px; margin-right: 5px" clearable>
                  <el-option v-for="(item, index) in this.townList" :label="item.name" :value="item.id" :key="index">
                  </el-option>
                </el-select> </el-form-item></el-col>
            <el-col :span="8"><el-form-item v-if="activeName === '2' && areaSelectData.province !== ''" label="库存类型"
                label-width="100px">
                <el-select size="small" class="choose-machine center" v-model="areaSelectData.jd_area_state"
                  placeholder="请选择" style="width: 100px">
                  <el-option label="全部" :value="0"></el-option>
                  <el-option label="有货" :value="1"></el-option>
                  <el-option label="无货" :value="2"></el-option>
                </el-select> </el-form-item></el-col>
          </el-row>
        </el-form>
      </template>

      <!-- 单独登录站点的筛选条件 且 不是礼包 -->
      <template v-if="!MixinIsFormEnterprise && +activeName != 3">
        <template slot="toolbar">
          <div class="row no-gutters align-items-center">
            <el-form-item label="分销价" label-width="68px">
              <el-input-number style="width: 110px" size="small" v-model="params.low_enterprice" placeholder="最低分销价" />
              <span style="margin: 0 5px">-</span>
              <el-input-number style="width: 110px" size="small" v-model="params.high_enterprice" placeholder="最高分销价" />
            </el-form-item>

            <el-form-item label="市场价" label-width="68px">
              <el-input-number style="width: 110px" size="small" v-model="params.low_mktprice" placeholder="最低市场价" />
              <span style="margin: 0 5px">-</span>
              <el-input-number style="width: 110px" size="small" v-model="params.high_mktprice" placeholder="最高市场价" />
            </el-form-item>
          </div>
          <div class="col"></div>
          <div class="col-auto">
            <el-input style="width: 320px" size="medium" placeholder="输入商品名称" v-model="keyword" clearable>
              <el-select slot="prepend" v-model="key_word" style="width: 120px">
                <el-option value="goods_name" label="商品名称"></el-option>
                <el-option value="goods_sn" label="商品编号"></el-option>
              </el-select>
            </el-input>
          </div>
          <div class="col-auto ml-2">
            <el-button @click="search" size="small" type="primary">
              搜索
            </el-button>
          </div>
        </template>
        <template slot="toolbar_btn">
          <el-button @click="addGoods" v-if="MixinIsFormEnterprise" type="primary" size="small">添加商品
          </el-button>
          <el-button size="small" type="primary" @click="handleBatchCommand('modify')">批量改价
          </el-button>
          <span style="margin: 0 10px" v-if="hasReviseTask">改价任务进行中...</span>
          <el-button size="small" type="primary" @click="handleBatchCommand('on')">批量上架
          </el-button>
          <el-button size="small" type="primary" @click="handleBatchCommand('off')">批量下架
          </el-button>
          <el-button size="small" type="primary" @click="handleBatchCommand('remove')">批量移除
          </el-button>

          <el-button size="small" @click="exportShop" type="primary" :loading="importLoading">导出商品
          </el-button>
          <el-button v-if="activeName !== '2'" size="small" type="primary" @click="handleBatchCommand('clear')">下架全部无货商品
          </el-button>
        </template>
        <el-form slot="search">
          <div class="row no-gutters align-items-center" style="margin-top: 8px">
            <el-row>
              <el-col :span="9"><el-form-item label="商品分类" label-width="100px">
                  <el-cascader clearable style="width: 320px" v-model="categoryParams" :options="categoryProxy.children"
                    :props="categoryProxy.props" placeholder="请选择商品分类" class="cas-select" /> </el-form-item></el-col>
              <el-col :span="5"><el-form-item label="商品状态" label-width="100px">
                  <el-select size="small" class="choose-machine center" v-model="params.shop_goods_status"
                    placeholder="请选择" clearable style="width: 100px">
                    <el-option label="全部" value=""></el-option>
                    <el-option v-for="item in goodsStatusList" :key="item.value" :label="item.label"
                      :value="item.value"></el-option>
                  </el-select> </el-form-item></el-col>

              <el-col :span="9">
                <el-form-item label="价格关系" label-width="100px">
                  <el-select style="width: 100px" size="small" class="choose-machine center" v-model="params.pre"
                    placeholder="选择价格" clearable>
                    <el-option v-for="item in list1" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                  <el-select style="width: 100px" size="small" class="choose-machine ml-2 center" v-model="params.sign"
                    placeholder="计算方式" clearable>
                    <el-option v-for="item in list2" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                  <el-select style="width: 100px" size="small" class="choose-machine ml-2 center" v-model="params.suf"
                    placeholder="选择价格" clearable>
                    <el-option v-for="item in list3" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="7"><el-form-item v-if="activeName !== '2'" label="库存状态" label-width="100px">
                  <el-select size="small" class="choose-machine center" v-model="params.has_goods" placeholder="请选择"
                    style="width: 100px">
                    <el-option label="全部" :value="0"></el-option>
                    <el-option label="有货" :value="1"></el-option>
                  </el-select> </el-form-item></el-col>
              <el-col :span="7">
                <el-form-item v-if="activeName === '2'" label="商品配送类型" label-width="100px">
                  <el-select style="width: 215px" v-model="params.ship_method" placeholder="请选择配送类型" clearable>
                    <el-option label="全部" value="" />
                    <el-option label="由厂家配送" :value="2" />
                    <el-option label="京东自营配送" :value="1" />
                  </el-select> </el-form-item></el-col>
            </el-row>
            <el-row>
              <!------------  getArea------------>
              <el-col :span="14"><el-form-item v-if="activeName === '2'" label="地区" label-width="100px">
                  <el-select size="small" class="choose-machine center" v-model="areaSelectData.province"
                    placeholder="省/直辖市" style="width: 100px; margin-right: 5px" clearable>
                    <el-option v-for="(item, index) in this.provinceList" :label="item.name" :value="item.id"
                      :key="index">
                    </el-option>
                  </el-select>
                  <el-select v-if="areaNum > 1" size="small" class="choose-machine center" v-model="areaSelectData.city"
                    placeholder="城市/地区" style="width: 100px; margin-right: 5px" clearable>
                    <el-option v-for="(item, index) in this.cityList" :label="item.name" :value="item.id" :key="index">
                    </el-option>
                  </el-select>
                  <el-select v-if="areaNum > 2" size="small" class="choose-machine center"
                    v-model="areaSelectData.county" placeholder="县/区" style="width: 100px; margin-right: 5px" clearable>
                    <el-option v-for="(item, index) in this.countyList" :label="item.name" :value="item.id"
                      :key="index">
                    </el-option>
                  </el-select>
                  <el-select v-if="areaNum > 3" size="small" class="choose-machine center" v-model="areaSelectData.town"
                    placeholder="乡镇/街道" style="width: 100px; margin-right: 5px" clearable>
                    <el-option v-for="(item, index) in this.townList" :label="item.name" :value="item.id" :key="index">
                    </el-option>
                  </el-select> </el-form-item></el-col>
              <el-col :span="10"><el-form-item v-if="activeName === '2' && areaSelectData.province !== ''" label="库存类型"
                  label-width="100px">
                  <el-select size="small" class="choose-machine center" v-model="areaSelectData.jd_area_state"
                    placeholder="请选择" style="width: 100px">
                    <el-option label="全部" :value="0"></el-option>
                    <el-option label="有货" :value="1"></el-option>
                    <el-option label="无货" :value="2"></el-option>
                  </el-select> </el-form-item></el-col>
            </el-row>
          </div>
        </el-form>
      </template>

      <template slot="table-columns" v-if="+activeName != 3">
        <el-table-column type="selection" />

        <el-table-column label="图片" width="60" fixed="left" class-name="goods-cover-wrapper">
          <template slot-scope="{ row }">
            <el-popover placement="right" trigger="hover">
              <img :src="row.thumbnail" alt="" style="width: 300px" />
              <div @click="opengoodsclassify(row)" slot="reference">
                <img :src="row.thumbnail" class="goods-cover" alt="" />
              </div>
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column label="商品名称" width="215px" :show-overflow-tooltip="true" fixed="left">
          <template slot-scope="{ row }">
            {{
              row.goods_name.replaceAll("amp;", "").replaceAll(" ", "&nbsp;")
            }}
          </template>
        </el-table-column>

        <el-table-column label="商品编号" width="120" fixed="left" prop="good_sn" />
        <el-table-column label="商品分类" width="120" fixed="left" prop="cat_name" />

        <el-table-column label="商品来源" width="80" :show-overflow-tooltip="true">
          <template slot-scope="{ row }">
            <template v-if="row.goods_source === 0"> 平台方 </template>
            <template v-if="row.goods_source === 1"> 京东 </template>
            <template v-if="row.goods_source === 9 || row.goods_source === 5">自有</template>
          </template>
        </el-table-column>

        <el-table-column label="所属供应商" width="100" show-overflow-tooltip
          v-if="MixinIsFormEnterprise && ((activeName === '0' && $store.getters.isSupplierName) || activeName === '4')">
          <template slot-scope="scope">
            {{ scope.row.seller_name }}
          </template>
        </el-table-column>

        <el-table-column v-if="MixinIsFormEnterprise" label="会员价" width="100" prop="price" sortable="custom">
          <template slot-scope="scope">
            {{ scope.row.price | unitPrice("￥") }}
          </template>
        </el-table-column>

        <el-table-column label="分销价" width="100" prop="enterprisePrice" sortable="custom">
          <template slot-scope="scope">
            {{ scope.row.enterprise_revise_price | unitPrice("￥") }}
          </template>
        </el-table-column>

        <el-table-column label="市场价" width="100" prop="market" sortable="custom">
          <template slot-scope="scope">
            {{ scope.row.mktprice | unitPrice("￥") }}
          </template>
        </el-table-column>

        <el-table-column v-if="MixinIsFormEnterprise" label="毛利率" width="100" prop="profit" sortable="custom">
          <template slot-scope="scope">
            {{ scope.row.enterprise_profit || 0 }}%
          </template>

          <template slot="header" slot-scope="{ row }">
            <el-tooltip effect="dark" content="毛利率 =（市场价 - 会员价）/ 市场价 * 100%" placement="top">
              <label class="cursor-pointer">毛利率</label>
            </el-tooltip>
          </template>
        </el-table-column>

        <el-table-column label="可售库存" width="100">
          <!-- 商品来源，0普通商品，1京东商品，2苏宁商品 -->
          <template slot-scope="scope">
            <template v-if="scope.row.goods_source === 1">
              <template v-if="quantityStatus">{{
                scope.row.stock_state_desc
              }}</template>
              <template v-else>
                <span v-if="scope.row.enable_quantity > 0">有货</span>
                <span v-else>无货</span>
              </template>
            </template>
            <template v-else> {{ scope.row.enable_quantity }}件 </template>
          </template>
        </el-table-column>

        <el-table-column label="实际库存" width="100">
          <!-- 商品来源，0普通商品，1京东商品，2苏宁商品 -->
          <template slot-scope="scope">
            <template v-if="scope.row.goods_source === 1">
              <template v-if="quantityStatus">{{
                scope.row.stock_state_desc
              }}</template>
              <template v-else>
                <span v-if="scope.row.enable_quantity > 0">有货</span>
                <span v-else>无货</span>
              </template>
            </template>
            <template v-else> {{ scope.row.quantity || 0 }}件 </template>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" width="130" prop="time" sortable="custom">
          <template slot-scope="scope">{{
            scope.row.create_time | unixToDate("yyyy-MM-dd hh:mm")
          }}</template>
        </el-table-column>

        <el-table-column label="总销售" width="100" prop="buynum" sortable="custom">
          <template slot-scope="scope">{{ scope.row.buy_count }}</template>
        </el-table-column>

        <el-table-column label="状态" width="120">
          <template slot-scope="scope">
            <span>{{ scope.row | marketStatus }}</span>
            <div class="under-reason" v-if="scope.row.shop_goods_status === 0" @click="showUnderReason(scope.row)">
              (下架原因)
            </div>
          </template>
        </el-table-column>

        <!-- <el-table-column label="上架状态" width="80" v-if="!MixinIsFormEnterprise">
          <template slot-scope="scope">
            <span>{{ scope.row | marketEnable }}</span>
          </template>
        </el-table-column>
        <el-table-column label="审核状态" width="80" v-if="!MixinIsFormEnterprise">
          <template slot-scope="scope">
            <span>{{ scope.row | authStatus }}</span>
          </template>
        </el-table-column>-->
        <el-table-column label="操作" width="220" fixed="right">
          <template slot-scope="scope">
            <!--<el-button
							size="mini"
							type="success"
							@click="handleEditGoods(scope.row)">详细
            </el-button>-->
            <el-button v-if="!MixinIsFormEnterprise" type="primary" size="mini"
              @click="revisePriceShow([{shop_goods_id:scope.row.id,goods_id:scope.row.goods_id}], false)" :disabled="hasReviseTask">
              改价
            </el-button>
            <el-button v-if="MixinIsFormEnterprise" type="primary" size="mini"
              @click="revisePriceShow([scope.row.goods_id], false)" :disabled="hasReviseTask">
              改价
            </el-button>
            <!-- <el-button
							size="mini"
							type="danger"
							v-if="scope.row.shop_goods_status === 0"
							@click="handleDeleteGoods(scope.row)">删除
            </el-button>-->
            <el-button size="mini" type="danger" v-if="scope.row.shop_goods_status === 1"
              @click="handleUnderGoods(scope.row)" :disabled="hasReviseTask">
              下架
            </el-button>
            <el-button size="mini" type="success" v-if="scope.row.shop_goods_status === 0"
              @click="handleUpGoods(scope.row)" :disabled="hasReviseTask">
              上架
            </el-button>
            <el-button size="mini" type="danger" @click="handleDeleteGoods(scope.row)" :disabled="hasReviseTask">
              移除
            </el-button>
            <!-- <el-button
							type="primary"
							size="mini"
							@click="handleStockGoods(scope.row)">库存
						</el-button>
						<el-button
							v-if="distributionSet"
							type="primary"
							size="mini"
							@click="handleRebate(scope.row)">返利
            </el-button>-->
          </template>
        </el-table-column>
      </template>

      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="pageData.page_no" :page-size="pageData.page_size" :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout" background :total="pageData.data_total" />
    </en-table-layout>

    <!--库存编辑-->
    <el-dialog title="库存编辑" :visible.sync="goodsStockshow" width="35%" class="pop-sku">
      <div align="center">
        <el-form :model="goodsStockData" ref="goodsStockData" v-if="goodsStocknums === 1" style="width: 50%"
          label-width="100px" :rules="rules">
          <el-form-item label="库存" prop="quantity">
            <el-input v-model="goodsStockData.quantity" />
          </el-form-item>
          <el-form-item label="待发货数">
            <el-input v-model="goodsStockData.deliver_goods_quantity" disabled />
          </el-form-item>
        </el-form>
        <en-table-layout :table-data="goodsStockData" :loading="loading" border v-if="goodsStocknums > 1"
          :span-method="arraySpanMethod" :stripe="false">
          <template slot="table-columns">
            <el-table-column v-for="(item, index) in goodsStockTitle" v-show="item.prop !== 'sku_id'"
              :label="item.label" :key="index">
              <template slot-scope="scope">
                <el-input v-if="item.prop === 'quantity'" @blur="checkQuantity(scope.row.quantity)"
                  v-model="scope.row.quantity" />
                <span v-if="item.prop !== 'quantity'">
                  {{ scope.row[item.prop] }}
                </span>
              </template>
            </el-table-column>
          </template>
        </en-table-layout>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="goodsStockshow = false">取 消</el-button>
        <el-button type="primary" @click="reserveStockGoods">确 定 </el-button>
      </div>
    </el-dialog>

    <!--下架原因-->
    <el-dialog title="下架原因" :visible.sync="isShowUnderReason" width="17%">
      <div align="center">{{ under_reason ? under_reason : "无" }}</div>
    </el-dialog>

    <!--改价-->
    <x-dialog :proxy="modifyPriceDialogRef">
      <el-form :model="revisePriceForm" ref="revisePriceForm" label-width="80px">
        <el-form-item label="挑选范围" prop="cou" v-show="batchModifyPrice">
          <el-radio-group v-model="cou">
            <el-radio :label="1">
              已勾选的商品{{ "(共" + idsList.length + "件商品)" }}
            </el-radio>
            <el-radio :label="2">
              所有商品{{ "(共" + pageData.data_total + "件商品)" }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="销售价" prop="revise_value">
          <div class="row no-gutters">
            <div class="col-auto">按照</div>
            <el-select v-model="revisePriceForm.price_type" clearable placeholder="请选择"
              style="width: 100px; margin-left: 8px">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
            <el-select v-model="revisePriceForm.symbol" clearable placeholder="请选择"
              style="width: 90px; margin-left: 8px">
              <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
            <el-input v-show="revisePriceForm.symbol !== 'EQUAL'" style="width: 160px; margin-left: 8px"
              placeholder="请输入内容" @change="handleInputChange" v-model="revisePriceForm.ratio" clearable></el-input>
            <div class="col-auto" style="margin-left: 8px" v-show="revisePriceForm.symbol !== 'EQUAL'">
              %
            </div>
          </div>
        </el-form-item>
      </el-form>
      <!--      <div slot="footer" class="text-picker-footer">-->
      <!--        <el-button @click="isRevisePrice = false">取 消</el-button>-->
      <!--        <el-button type="primary" @click="revisePrice">确 定</el-button>-->
      <!--      </div>-->
    </x-dialog>

    <!--分销返利-->
    <el-dialog title="分销返利" :visible.sync="isShowDisRebate" width="24%">
      <el-form :model="disRebateData" label-width="100px" :rules="disRules" ref="disRebateData" status-icon>
        <el-form-item label="1级返利" prop="grade1Rebate">
          <el-input v-model="disRebateData.grade1Rebate">
            <template slot="prepend"> ¥ </template>
          </el-input>
        </el-form-item>
        <el-form-item label="2级返利" prop="grade2Rebate">
          <el-input v-model="disRebateData.grade2Rebate">
            <template slot="prepend"> ¥ </template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="reserveDisSet('disRebateData')">
            保存
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <en-goods-picker type="seller" shopGoods :show="showDialog" :api="goodsApi" :categoryApi="categoryApi"
      :multipleApi="multipleApi" :defaultData="goodsIds" :limit="maxsize" @confirm="refreshFunc"
      @close="showDialog = false" />

    <!--改价-->
    <en-gaijia-picker :showm="dialogShopShow1" :btnShow="false" :shangxiajia="false" @close="close" @res="res"
      :idsList="idsList" :allId="pageData.data_total" @confirm1="handleShopPickerConfirm1" :is_qiye_flag="false"
      :batch="batchModifyPrice" :default_enterprise_price="default_enterprise_price" />

    <!-- 商品详情弹窗 -->
    <el-dialog custom-class="goodsclassify" :visible.sync="dialogVisible" :close-on-click-modal="false"
      :close-on-press-escape="false" append-to-body width="1150px">
      <div slot="title" class="goods-picker-title">
        <span></span>
        商品详情
      </div>
      <goodsclassify v-if="dialogVisible" :formData="goodsclassifyData"></goodsclassify>
    </el-dialog>
  </div>
</template>

<script>
import * as API_goods from "@/api/goods";
import * as API_Combo from "@/api/combo-card-shop/combo2";
import { getShopExtAuth } from "@/api/order"
import { CategoryPicker } from "@/components";
import { RegExp, Foundation } from "@/../ui-utils";
import EnTableLayout from "@/../ui-components/TableLayout/src/main";
import XDialog from "@/components/x-dialog/x-dialog";
import { $xDialog } from "@/components/x-dialog/dialog.proxy";
import { $goodsCategory } from "@/pages/goods/services/goods-category";
import * as API_Login from "@/api/login";

import goodsclassify from "@/components/GoodsClassify/goodsclassify";
import {
  getJDProvince,
  getJDCity,
  getJDCounty,
  getJDTown,
} from "../../api/goods";
import * as API_Floor from "@/api/floor";

import * as API_gift from "@/api/giftGoods";

// import TagZY from '@/assets/goods/tag-zy.png';
// import TagJD from '@/assets/goods/tag-jd.png';
// import TagJX from '@/assets/goods/tag-jx.png';

export default {
  name: "shopGoodsList",
  components: {
    XDialog,
    EnTableLayout,
    CategoryPicker,
    goodsclassify,
  },
  data () {
    const checkQuantity = (rule, value, callback) => {
      if (!value && value !== 0) {
        return callback(new Error("库存不能为空"));
      }
      setTimeout(() => {
        if (!/^[0-9]\d*$/.test(value)) {
          callback(new Error("请输入整数"));
        } else if (!(parseInt(value) >= 0 && parseInt(value) <= 99999999)) {
          callback(new Error("请输入0 - 99999999之间的正整数"));
        } else {
          callback();
        }
      }, 500);
    };
    const checkMoney = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("返利金额不能为空"));
      }
      setTimeout(() => {
        if (!RegExp.money.test(value)) {
          callback(new Error("请输入正确的金额"));
        } else if (parseFloat(value) < 0 || parseFloat(value) > 99999999) {
          callback(new Error("请输入0~99999999之间的金额"));
        } else {
          callback();
        }
      }, 500);
    };

    return {
      merges: [],
      dialogVisible: false,//商品详情弹窗
      goodsclassifyData: {},//商品详情传参
      force_exchange_goods_open: false,
      waitSold: false, // 是否可以单独售卖
      waitExchange: false, // 是否可以智能换货
      waitSoldTimer: null,
      waitExchangeTimer: null,
      restrictNum: null,
      keyword: "",
      key_word: "goods_name",
      modifyPriceDialogRef: $xDialog.create({
        width: "600px",
        afterDismiss: () => this.revisePriceClosed(),
        beforeConfirm: () => this.revisePrice(),
      }),
      kindList: [],
      kindDialog: $xDialog.create({
        title: "商品展示",
        width: "1024px",
        disableCancel: true,
        disableConfirm: true,
      }),
      areaNum: 1,
      provinceList: [],
      cityList: [],
      countyList: [],
      townList: [],
      selectCarmiTable:[],//复选框勾选内容
      areaSelectData: {
        province: "",
        city: "",
        county: "",
        town: "",
        jd_area_state: 1,
      },
      history: {
        remark: "",
      },
      shopTypeList: [
        {
          label: "精选优品",
          value: 0,
        },
        {
          label: "自有商品",
          value: 1,
        },
        {
          label: "京东商品",
          value: 2,
        }
      ],
      activeName: "2",
      dataForm: {},
      /** 列表loading状态 */
      loading: false,
      isRevisePrice: false,
      revisePriceTitle: "",
      options: [
        {
          label: "分销价",
          value: 1,
        },
        {
          label: "市场价",
          value: 2,
        },
      ],
      options1: [
        {
          label: "上调",
          value: "UP",
        },
        {
          label: "下降",
          value: "DOWN",
        },
        {
          label: "等于",
          value: "EQUAL",
        },
      ],
      revisePriceList: [],
      revisePriceForm: {
        price_type: 1,
        symbol: "UP",
        ratio: 0,
      },
      cou: 1,
      idsList: [],
      /** 商品选择器最大长度*/
      maxsize: 0,

      showDialog: false,
      /** 商品选择器列表api*/
      goodsApi: "seller/goods/list-all",

      multipleApi: "seller/goods/@ids/details",

      /** 商城分类api */
      categoryApi: "seller/goods/category/@id/children-all",

      /** 商品ids */
      goodsIds: [],

      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20,
        self_goods: "2",
        low_enterprice: "",
        high_enterprice: "",
        category_path: "",
        keyword: "",
        sort: "",
        goods_name: "",
        goods_sn: "",
        has_goods: 0,
        high_mktprice: "",
        low_mktprice: "",
        shop_goods_status: "",
        pre: "",
        sign: "",
        suf: "",
        // 毛利率
        profit_min: "",
        profit_max: "",
        // 利润率
        shop_profit_min: "",
        shop_profit_max: "",
        supplier_enable: 1,
        market_enable: 1,
      },
      /** 列表数据 */
      tableData: {},

      /** 列表分页数据 */
      pageData: {},

      /** 商品状态列表 */
      goodsStatusList: [
        {
          value: 0,
          label: "已下架",
        },
        {
          value: 1,
          label: "已上架",
        },
      ],

      /** 商品类型 NORMAL 正常商品 POINT 积分商品 */
      goods_type: "",

      /** 当前商品分组*/
      categoryId: "",

      /** 当前商品id*/
      goodsId: "",

      /** 商品库存显示*/
      goodsStockshow: false,

      /** 是否显示下架原因 */
      isShowUnderReason: false,

      /** 下架原因 */
      under_reason: "",

      /** 库存商品数量*/
      goodsStocknums: 0,

      /** 商品库存列表数据*/
      goodsStockData: null,

      /** 商品库存列表表头数据 */
      goodsStockTitle: [],

      /** 要合并的列的位置数组 */
      concactArray: [],

      /** 校验规则 */
      rules: {
        quantity: [
          {
            validator: checkQuantity,
            trigger: "blur",
          },
        ],
      },

      /** 店铺信息 */
      shopInfo: this.$store.getters.shopInfo,

      /** 分销设置是否开启 1开启 0关闭 */
      distributionSet: 0,

      /** 是否显示分销返利弹框 */
      isShowDisRebate: false,
      importLoading: false,
      /** 分销返利数据 */
      disRebateData: {
        /** 商品id */
        goods_id: 0,

        /** 1级返利 */
        grade1Rebate: 0,

        /** 2级返利 */
        grade2Rebate: 0,
      },
      batchModifyPrice: false,
      /** 分销返利校验规则 */
      disRules: {
        grade1Rebate: [
          {
            required: true,
            message: "1级返利金额不能为空",
            trigger: "blur",
          },
          {
            validator: checkMoney,
            trigger: "blur",
          },
        ],
        grade2Rebate: [
          {
            required: true,
            message: "2级返利金额不能为空",
            trigger: "blur",
          },
          {
            validator: checkMoney,
            trigger: "blur",
          },
        ],
      },
      list1: [],
      list2: [
        {
          label: "大于",
          value: "gt",
        },
        {
          label: "等于",
          value: "eq",
        },
        {
          label: "小于",
          value: "lt",
        },
      ],
      list3: [
        {
          label: "市场价",
          value: "mkt",
        },
        {
          label: "会员价",
          value: "mem",
        },
        {
          label: "分销价",
          value: "dis",
        },
        {
          label: "销售价",
          value: "sale",
        },
      ],
      dialogShopShow1: false,
      pa: {},
      hasReviseTask: true, // 后台是否有正在进行中的任务，如果有为'OK'，此时不能进行改价,否则无，可以改价
      reviseTaskTimer: null,
      firstCategory: [],
      secondCategory: [],
      threeCategory: [],
      categoryParams: "",
      is_qiye_flag: false,
      categoryProxy: $goodsCategory.create(),
      quantityStatus: false,
      navList: [],
      activeIndex: null,
      gift: {
        lowEnterPrice: undefined,
        highEnterPrice: undefined,
        highComboPrice: undefined,
        lowComboPrice: undefined,
        key_word: "gift_name",
        keyword: "",
        time_range: ["", ""],
        create_time_start: "",
        create_time_end: "",
        goodsExchange: "",
        comboStatus: "",
        multipleSelection: [],
        sort: "",
      },
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now();
        },
      },
      copyValidateForm: {
        id: "",
        gift_name: "",
        web_gift_name: "",
      },

      copyDialog: $xDialog.create({
        width: "500px",
        beforeConfirm: () => this.submitCopyGift(),
      }),
      remarkValidateForm: {
        id: "",
        remark: "",
      },
      remarkDialog: $xDialog.create({
        width: "500px",
        beforeConfirm: () => this.submitGiftRemark(),
      }),
      default_enterprise_price: {},
    };
  },
  watch: {
    "areaSelectData.province": {
      handler (newValue) {
        this.areaSelectData.city = "";
        this.areaSelectData.county = "";
        this.areaSelectData.town = "";
        if (newValue === "") {
          this.areaNum = 1;
          this.getAreaHandle("province");
        } else {
          this.areaNum = 2;
          this.getAreaHandle("city", { id: newValue });
        }
      },
      immediate: true,
      deep: true,
    },
    "areaSelectData.city": {
      handler (newValue) {
        this.areaSelectData.county = "";
        this.areaSelectData.town = "";
        if (this.areaSelectData.province === "") return;
        if (newValue === "") this.areaNum = 2;
        else {
          this.areaNum = 3;
          this.getAreaHandle("county", { id: newValue });
        }
      },
      deep: true,
    },
    "areaSelectData.county": {
      handler (newValue) {
        this.areaSelectData.town = "";
        if (this.areaSelectData.city === "") return;
        if (newValue === "") this.areaNum = 3;
        else {
          this.areaNum = 4;
          this.getAreaHandle("town", { id: newValue });
        }
      },
      deep: true,
    },
  },
  filters: {
    /** 销售状态格式化 */
    marketStatus (row) {
      switch (row.shop_goods_status) {
        case 0:
          return "已下架";
        case 1:
          return "已上架";
      }
    },
    marketEnable (row) {
      return row.market_enable === 1 ? "上架" : "下架";
    },
    authStatus (row) {
      if (row.is_auth === 0) {
        return "待审核";
      } else if (row.is_auth === 1) {
        return "审核通过";
      } else if (row.is_auth === 2) {
        return "审核拒绝";
      }
    },
  },
  created () {
    switch (sessionStorage.getItem("shopGoodsListType")) {
      case "1":
        this.params.skip = 1;
        break;
      case "2":
        this.params.skip = 2;
        break;
      case "3":
        this.params.skip = 3;
        break;
      case "4":
        this.params.skip = 4;
        break;
      default:
        break;
    }

    if (sessionStorage.getItem("MixinIsFormEnterprise")) {
      this.MixinIsFormEnterprise = JSON.parse(
        sessionStorage.getItem("MixinIsFormEnterprise")
      );
    }
    this.getNavList();
    API_Login.getLoginType().then((res) => {
      if (res && res.parentLogin === 1) {
        this.MixinIsFormEnterprise = true;
        sessionStorage.setItem("MixinIsFormEnterprise", true);
      } else sessionStorage.setItem("MixinIsFormEnterprise", false);
    });
    this.list1 = [
      {
        label: "市场价",
        value: "mkt",
      },
      {
        label: "分销价",
        value: "dis",
      }
    ];
    if (this.MixinIsFormEnterprise) {
      this.list1.push({
        label: "会员价",
        value: "mem",
      });
    }
  },
  activated () {
    this.GET_GoodsList();
    this.$store.commit("DEL_VISITED_VIEWS", this.$route);
  },
  mounted () {
    this.getShopExtAuth();
    this.GET_GoodsList();
    this.getDistributionSet();
    this.loadReviseTask();
    this.checkWaitSold();
    this.checkWaitExchange();
    this.$store.commit("DEL_VISITED_VIEWS", this.$route);
  },
  destroyed () {
    this.reviseTaskTimer && clearInterval(this.reviseTaskTimer);
    this.waitSoldTimer && clearTimeout(this.waitSoldTimer)
    this.waitExchangeTimer && clearTimeout(this.waitExchangeTimer)
  },
  methods: {
    batchOps (ops) {//批量操作
      if (!this.waitExchange) {//判断是否禁用      
        switch (ops) {
          case 'open':
            return this.openGoodsExchange();
          case 'close':
            return this.closeGoodsExchange();
        }
      }
    },

    opengoodsclassify (row) {//打开商品详情弹窗
      this.goodsclassifyData = {
        from: "1",
        goods_id: row.goods_id,
        good_sn: row.good_sn,
        revise_price: row.revise_price,
        islogin: this.MixinIsFormEnterprise,//是否单独登录
        shop_profit: row.shop_profit
      }
      this.dialogVisible = true
    },
    genGoodsData (goods_volist) {
      let ret = [];
      goods_volist.forEach((item) => {
        let profit_price = 0;
        let {
          goods_id,
          goods_name,
          supplier_name,
          goods_alias,
          num,
          id,
          seq,
          exchange_goods_num,
          web_thumbnail,
          web_gift_name,
          shop_goods_vo: {
            enterprise_revise_price,
            goods_do: {
              thumbnail,
              ext_goods_id,
              goods_source,
              mktprice,
              price,
              enable_quantity,
              self_operated,
              sn,
            },
            revise_price,
            shop_goods_status,
            disabled, // 值等于0时表示大平台下架商品
          },
        } = item;
        ret.push({
          sn,
          id,
          goods_alias,
          web_gift_name,
          exchange_goods_num,
          web_thumbnail,
          goods_id,
          thumbnail,
          goods_name,
          supplier_name,
          ext_goods_id,
          goods_source,
          revise_price,
          mktprice,
          enterprise_revise_price,
          price,
          num,
          seq,
          enable_quantity,
          shop_goods_status,
          disabled,
          profit_price,
          self_operated,
        });
      });
      return ret;
    },

    showKindDialog (comboId) {
      API_Combo.getCombo(comboId).then((res) => {
        this.kindList = this.genGoodsData([...res.goods_volist]);
        this.kindDialog.display()
      })
    },

    getShopExtAuth () {
      getShopExtAuth().then(res => {
        if (res.force_exchange_goods_open === "CLOSE") {
          this.force_exchange_goods_open = false;
        }
        else {
          this.force_exchange_goods_open = true;
        }
      })
    },

    generateNewName (name) {
      // 定义正则表达式，匹配名字后面的空格和数字
      const pattern = /(\s-\d+)$/;

      if (pattern.test(name)) {
        // 如果名字已经符合要求，提取数字并加1
        // const num = parseInt(name.match(/\d+/)[0]) + 1;
        // const newName = name.replace(pattern, ` -${num}`);
        const newName = `${name} -1`;
        return newName;
      } else {
        // 如果名字不符合要求，附加 -1
        const newName = `${name} -1`;
        return newName;
      }
    },
    // 检查单独售卖修改任务是否结束
    checkWaitSold () {
      this.waitSold = true;
      API_goods.checkSoldSeparately().then((res) => {
        if (res === "OK") {
          this.waitSold = false;
          this.waitSoldTimer && clearTimeout(this.waitSoldTimer);
          if (this.activeName != 3) {
            this.GET_GoodsList();
          }
        } else {
          this.waitSold = true;
          this.waitSoldTimer = setTimeout(() => {
            this.checkWaitSold();
          }, 500);
        }
      });
    },
    // 检查智能换货修改任务是否结束
    checkWaitExchange () {
      this.waitExchange = true;
      API_goods.checkGoodsAiExchange().then((res) => {
        if (res === "OK") {
          this.waitExchange = false;
          this.waitExchangeTimer && clearTimeout(this.waitExchangeTimer);
          if (this.activeName == 3) {
            this.GET_GoodsList();
          }
        } else {
          this.waitExchange = true;
          this.waitExchangeTimer = setTimeout(() => {
            this.checkWaitExchange();
          }, 500);
        }
      });
    },
    getGoodsSource (key = this.activeName) {
      let goods_source;
      switch (+key) {
        case 0:
          goods_source = 0; // 平台方
          break;
        case 1:
          goods_source = 9; // 自有
          break;
        case 2:
          goods_source = 1; // 京东
          break;
        default:
          break;
      }
      return goods_source;
    },
    getListParams () {
      let params = { ...this.params };
      if (this.activeName === "2" && this.areaSelectData.province !== "") {
        let jd_area = "" + this.areaSelectData.province + "_";
        if (this.areaSelectData.city !== "")
          jd_area += this.areaSelectData.city + "_";
        else jd_area += "0_";
        if (this.areaSelectData.county !== "")
          jd_area += this.areaSelectData.county + "_";
        else jd_area += "0_";
        if (this.areaSelectData.town !== "")
          jd_area += this.areaSelectData.town;
        else jd_area += "0";
        params.jd_area = jd_area;
        params.jd_area_state = this.areaSelectData.jd_area_state;
      } else if (params.jd_area) {
        delete params.jd_area;
        delete params.jd_area_state;
      }
      params.is_combo = 0;
      params.supplier_enable = 1;
      return params;
    },
    getGiftListParams () {
      const { page_no, page_size } = this.params;
      const {
        lowEnterPrice,
        highEnterPrice,
        key_word,
        keyword,
        create_time_start,
        create_time_end,
        goodsExchange,
        comboStatus,
        highComboPrice,
        lowComboPrice,
        sort,
      } = this.gift;
      let err = [];
      if (
        !this.isEmpty(lowEnterPrice) &&
        !this.isEmpty(highEnterPrice) &&
        lowEnterPrice > highEnterPrice
      ) {
        err.push("最低分销总价应小于等于最高分销总价");
      }
      if (
        !this.isEmpty(lowComboPrice) &&
        !this.isEmpty(highComboPrice) &&
        lowComboPrice > highComboPrice
      ) {
        err.push("最低套餐价应小于等于最高套餐价");
      }
      if (err.length) {
        for (let i = 0; i < err.length; i++) {
          setTimeout(() => {
            this.$message.error(err[i]);
          }, 300 * i);
        }
        return false;
      }
      return {
        page_no,
        page_size,
        lowEnterPrice,
        highEnterPrice,
        create_time_start,
        create_time_end,
        goodsExchange,
        comboStatus,
        highComboPrice,
        lowComboPrice,
        sort,
      };
    },
    changeGoodsSale (e, row) {
      API_goods.changeSaleStatus(row.goods_id, +e)
        .then((res) => {
          this.$message.success("操作成功");
          // this.GET_GoodsList()
        })
        .catch(() => {
          row.sold_separately_enable = e == 1 ? 0 : 1;
        });
    },
    changeGoodsExchange (e, row) {
      API_goods.editAiExchangeTh(row.id, +e)
        .then((res) => {
          this.$message.success("操作成功");
          // this.GET_GoodsList()
        })
        .catch(() => {
          row.goods_exchange = e == 1 ? 0 : 1;
        });
    },
    changeGiftTime (e) {
      if (!e || e.length < 2) {
        this.gift.create_time_start = "";
        this.gift.create_time_end = "";
      } else {
        this.gift.create_time_start = e[0].getTime() / 1000;
        this.gift.create_time_end = e[1].getTime() / 1000 + 86399;
      }
    },
    submitGiftRemark () {
      const { id, remark } = this.remarkValidateForm;
      API_gift.editGiftRemark(id, remark)
        .then((res) => {
          this.remarkDialog.visible = false;
          this.GET_GoodsList();
          this.$message.success("修改成功");
          if (remark) {
            this.history.remark = remark;
          }
        })
        .catch(() => { });
      return false;
    },
    showCopyGiftBox (row) {
      const { id, gift_name, web_gift_name } = row;
      this.copyValidateForm = {
        id,
        gift_name: this.generateNewName(gift_name),
        web_gift_name: this.generateNewName(web_gift_name),
      };
      this.copyDialog.display({
        title: "复制",
      });
      this.$nextTick(() => {
        this.$refs["copyValidateForm"].validate();
      });
    },
    submitCopyGift () {
      this.$refs["copyValidateForm"].validate((valid) => {
        if (!valid) {
          return false;
        }
        console.log(this.copyValidateForm);

        API_gift.copyCombo(this.copyValidateForm).then((res) => {
          this.copyDialog.visible = false;
          this.GET_GoodsList();
        });
      });
      return false;
    },

    delGiftGoods (row) {
      this.$confirm(`确定要删除该商品吗？删除后无法还原该商品。`, "删除提示", {
        type: "warning",
      })
        .then(() => {
          API_gift.delGiftGoods(row.id)
            .then((res) => {
              this.GET_GoodsList();
              this.$message.success("删除成功");
            })
            .catch(() => { });
        })
        .catch(() => { });
    },
    editGiftRemark (row) {
      this.remarkValidateForm = {
        id: row.id,
        remark: row.remark || this.history.remark || "",
      };
      this.remarkDialog.display({
        title: "备注信息",
      });
    },
    isEmpty (value) {
      return (
        value === undefined ||
        value === null ||
        (typeof value == "object" && Object.keys(value).length == 0) ||
        (typeof value == "string" && value.trim().length == 0)
      );
    },
    giftSearch () {
      // console.log(this.gift);
      this.GET_GoodsList();
    },
    getGiftGoodsList () {
      const { page_no, page_size } = this.params;
      const {
        lowEnterPrice,
        highEnterPrice,
        key_word,
        keyword,
        create_time_start,
        create_time_end,
        goodsExchange,
        comboStatus,
        highComboPrice,
        lowComboPrice,
        sort,
      } = this.gift;
      let err = [];
      if (
        !this.isEmpty(lowEnterPrice) &&
        !this.isEmpty(highEnterPrice) &&
        lowEnterPrice > highEnterPrice
      ) {
        err.push("最低分销总价应小于等于最高分销总价");
      }
      if (
        !this.isEmpty(lowComboPrice) &&
        !this.isEmpty(highComboPrice) &&
        lowComboPrice > highComboPrice
      ) {
        err.push("最低套餐价应小于等于最高套餐价");
      }
      if (err.length) {
        for (let i = 0; i < err.length; i++) {
          setTimeout(() => {
            this.$message.error(err[i]);
          }, 300 * i);
        }
        this.loading = false;
        return;
      }
      let params = {
        page_no,
        page_size,
        lowEnterPrice,
        highEnterPrice,
        create_time_start,
        create_time_end,
        goodsExchange,
        comboStatus,
        highComboPrice,
        lowComboPrice,
        sort,
      };
      if (key_word) params[key_word] = keyword;
      API_gift.getGiftGoodsList({
        ...params,
      }).then(
        (response) => {
          if (this.params.page_no !== 1 && response.data.length === 0) {
            this.params.page_no -= 1;
            this.GET_GoodsList();
          }
          this.loading = false;
          this.pageData = response;
          // 重新绘制表格
          // this.$refs.tableLayout.$refs.table.doLayout();
        },
        (err) => {
          this.loading = false;
        }
      );
    },
    toEditGiftGoods (row) {
      this.$store.dispatch("addVisitedViews", this.$route);
      this.$router.push({
        name: "editGiftGoods",
        params: {
          id: row.id,
        },
      });
    },
    addGiftGodds () {
      this.$store.dispatch("addVisitedViews", this.$route);
      this.$router.push({
        name: "editGiftGoods",
      });
    },
    openGoodsExchange () {
      console.log(this.gift.multipleSelection);
      let list = this.gift.multipleSelection.map(item => item.id);
      if (list.length > 0) {
        API_goods.changeGoodsAiExchange({
          combo_ids: list,
          ai_exchange: 1,
        }, 0).then((res) => {
          this.$message.success("操作成功");
          this.checkWaitExchange();
        });
        return;
      }
      this.$confirm(`确定要将当前全部礼包的【智能换货】开关开启吗？`, "提示", {
        type: "none",
      })
        .then(() => {
          if (!this.getGiftListParams()) {
            return;
          }
          API_goods.changeGoodsAiExchange({
            ...this.getGiftListParams(),
            combo_ids: [],
            ai_exchange: 1,
          }, 1).then((res) => {
            this.$message.success("操作成功");
            this.checkWaitExchange();
          });
        })
        .catch(() => { });
    },
    closeGoodsExchange () {
      let list = this.gift.multipleSelection.map(item => item.id);
      if (list.length > 0) {
        API_goods.changeGoodsAiExchange({
          combo_ids: list,
          ai_exchange: 0,
        }, 0).then((res) => {
          this.$message.success("操作成功");
          this.checkWaitExchange();
        });
        return;
      }
      this.$confirm(`确定要将当前全部礼包的【智能换货】开关关闭吗？`, "提示", {
        type: "none",
      })
        .then(() => {
          if (!this.getGiftListParams()) {
            return;
          }
          API_goods.changeGoodsAiExchange({
            ...this.getGiftListParams(),
            combo_ids: [],
            ai_exchange: 0,
          }, 1).then((res) => {
            this.$message.success("操作成功");
            this.checkWaitExchange();
          });
        })
        .catch(() => { });
    },

    restrictCancel (i) {
      this.activeIndex = null;
      this.restrictNum = "";
    },
    changNum (e, i) {
      console.log(e);
      this.restrictNum = e.match(/^[1-9]\d*$/g);
      console.log(this.restrictNum);
      this.$forceUpdate();
    },
    restrictChang (i) {
      this.activeIndex = i;
      this.restrictNum =
        `${this.pageData.data[i].restrict_num}` === "-1"
          ? ""
          : this.pageData.data[i].restrict_num;
      console.log(this.restrictNum);
    },
    restrictBlur (i) {
      this.pageData.data[i].restrict_num = !this.restrictNum
        ? -1
        : this.restrictNum;
      const params = this.pageData.data[i];
      API_goods.shopGoodsRestrict(params.id, params.restrict_num).then(
        (res) => {
          this.activeIndex = null;
          this.restrictNum = "";
        }
      );
    },
    async getNavList () {
      const res = await API_Floor.getNav()
      this.navList = res.map((item) => {
        switch (item.navigation_id) {
          case 65:
            item.navigation_id = "2";
            break;
          case 63:
            item.navigation_id = "1";
            break;
          case 66:
            item.navigation_id = "0";
            break;
          case 71://自有供应商
            item.navigation_id = '4';
            break
        }
        return item;
      });
      this.navList = [
        ...this.navList
      ];
    },
    // 标签切换
    handleClick (tab) {
      this.pageData.data = [];
      this.params = {
        has_goods: 0,
        page_no: 1,
        page_size: 20,
        self_goods: tab.name,
      };

      this.$refs.tableLayout.$refs.table.clearSort();
      // 将重新绘制表格放到标签切换的时候
      this.$nextTick(() => {
        this.$refs.tableLayout.$refs.table.doLayout();
      });
      if (this.activeName == 3) this.gift.key_word = "gift_name";
      this.GET_GoodsList();
    },
    loadReviseTask () {
      // 判断是否有改价任务 OK没有任务可以改价 NO有任务不能改价
      this.reviseTaskTimer = setInterval(() => {
        API_goods.hasReviseTask().then((res) => {
          if (res === "OK") {
            this.hasReviseTask = false;
            clearInterval(this.reviseTaskTimer);
          } else {
            this.hasReviseTask = true;
          }
        });
      }, 1000);
    },
    handleInputChange () {
      if (this.revisePriceForm.ratio < 0) {
        this.revisePriceForm.ratio = 0;
        // revisePriceForm.ratio  symbol
      } else if (
        this.revisePriceForm.symbol === "DOWN" &&
        this.revisePriceForm.ratio >= 100
      ) {
        this.revisePriceForm.ratio = 100;
      }
    },
    uderShops () {
      this.$confirm("是否要下架全部无货商品?", "提示", {
        type: "warning",
      }).then(() => {
        API_goods.underShops().then((res) => {
          this.$message.success("下架成功");
          this.GET_GoodsList();
        });
      });
    },
    addGoods () {
      this.$router.push({
        path: "/shop-goods/market",
      });
    },
    exportShop () {
      this.loading = true;
      const params = { ...this.params };
      // this.params.page_no = 1;
      // this.params.page_size = 99999;
      params.page_no = 1;
      params.page_size = 99999;
      API_goods.getShopGoodsList(params).then(
        (response) => {
          this.loading = false;
          // 商品来源，0普通商品，1京东商品，2苏宁商品
          const json = {
            sheet_name: "商品列表",
            sheet_values: response.data.map((item) => ({
              商品编号: item.good_sn,
              商品分类: item.cat_name,
              商品名称: item.goods_name
                .replaceAll("amp;", "")
                .replaceAll(" ", "&nbsp;"),
              匹配礼包数: item.match_gift_num || 0,
              商品来源: item.seller_name,
              市场价: Foundation.formatPrice(item.mktprice),
              销售价: Foundation.formatPrice(item.revise_price),
              分销价: Foundation.formatPrice(item.enterprise_revise_price),
              利润率: (item.shop_profit || 0) + '%',
              实际库存:
                item.goods_source === 1
                  ? item.quantity > 0
                    ? "有货"
                    : "无货"
                  : item.quantity,
              // 终身限购数: item.restrict_num === -1 ? "不限" : item.restrict_num,
              可售库存:
                item.goods_source === 1
                  ? item.enable_quantity > 0
                    ? "有货"
                    : "无货"
                  : item.enable_quantity,
              创建时间: Foundation.unixToDate(item.create_time),
              总销售: item.buy_count,
              状态: item.shop_goods_status == 0 ? "已下架" : "已上架",
              // 状态原因: item.shop_under_message ? item.shop_under_message : '',
            })),
          };
          this.MixinExportJsonToExcel(json, "商品列表");
        },
        (err) => {
          this.loading = false;
        }
      );
    },
    clearRes () {
      this.params.self_goods = "";
      this.params.low_enterprice = "";
      this.params.high_enterprice = "";
      this.params.low_mktprice = "";
      this.params.high_mktprice = "";
      this.params.shop_goods_status = "";
      this.params.pre = "";
      this.params.sign = "";
      this.params.suf = "";
      this.params.has_goods = 0;
      this.params.page_no = 1;
      this.params.page_size = 10;
      this.params.goods_name = "";
      this.categoryParams = "";
      this.gift = {
        lowEnterPrice: undefined,
        highEnterPrice: undefined,
        highComboPrice: undefined,
        lowComboPrice: undefined,
        key_word: "gift_name",
        keyword: "",
        time_range: ["", ""],
        create_time_start: "",
        create_time_end: "",
        goodsExchange: "",
        comboStatus: "",
        multipleSelection: [],
      };
      this.GET_GoodsList();
    },
    changeInit () {
      this.GET_GoodsList();
    },
    sortChange () {
      this.GET_GoodsList();
    },
    revisePriceClosed () {
      this.revisePriceForm = {
        price_type: 1,
        symbol: "UP",
        ratio: 0,
      };
    },
    /** 保存商品选择器选择的商品 */
    refreshFunc (val) {
      let tableData = val;
      /** 去重 转化 */
      const res = new Map();
      tableData = tableData.filter(
        (key) => !res.has(key.goods_id) && res.set(key.goods_id, 1)
      );
      this.goodsIds = tableData.map((key) => {
        return key.goods_id;
      });

      this.goodsIds.forEach((item) => {
        API_goods.selectShopGoods({
          goods_id: item,
          shop_goods_status: 0,
          revise_type: 2,
          revise_value: 0,
        }).then(() => {
          this.$message.success("添加成功");
          this.GET_GoodsList();
        });
      });
    },
    close () {
      this.dialogShopShow1 = false;
    },
    res () {
      // this.dialogShopShow = true;
      this.dialogShopShow1 = false;
    },
    handleShopPickerConfirm1 (item) {
      this.dialogShopShow1 = false;
      this.pa.enterprise_price = item.enterprise_price;
      this.pa.shop_goods_status = item.shop_goods_status;
      this.pa.shop_price = item.shop_price;
      this.pa.change_price = 1;
      if (item.cop === "1") {
        this.pa.choose_type = 1;
        this.pa.goods_ids = this.getGoodsId();
      } else {
        this.pa.goods_ids = [];
        this.pa.choose_type = 2;
      }

      this.pa.goods_source = this.goods_source;

      this.pa.param = { ...this.params };

      if (this.batchModifyPrice) {
        this.pa.is_batch = 0;
      } else {
        this.pa.is_batch = 1;
      }
      API_goods.sendShop(this.pa).then((res) => {
        this.$message.success("改价任务已开启!");
        this.hasReviseTask = true;
        this.GET_GoodsList();
        this.loadReviseTask();
        this.checkWaitSold();
        this.checkWaitExchange();
      });
    },
    getGoodsId () {
      let arr = [];
      if (!this.MixinIsFormEnterprise) {
        for (let i = 0; i < this.idsList.length; i++) {
          arr.push(this.idsList[i]);
        }
      } else {
        for (let i = 0; i < this.goodsIds.length; i++) {
          arr.push(this.goodsIds[i]);
        }
      }
      return arr;
    },

    handleBatchCommand (cmd) {
      switch (cmd) {
        case "modify":
          if (!this.MixinIsFormEnterprise) {
            return this.revisePriceShow(this.idsList, true);
          } else {
            return this.revisePriceShow(this.selectCarmiTable, true);
          }
        case "on":
          return this.handleUpGoods();
        case "off":
          return this.handleUnderGoods();
        case "remove":
          return this.handleDeleteGoods();
        case "clear":
          return this.uderShops();
      }
    },

    /** 商品改价 */
    async revisePriceShow (list, type) {
      if (!(list && list.length && list.length > 0)) {
        this.$message.error("请勾选商品");
        return;
      }
      this.batchModifyPrice = type;

      if (!this.MixinIsFormEnterprise) {
        // this.isRevisePrice = true;
        this.revisePriceList =type?list:list.map(item=>item.shop_goods_id);
        this.modifyPriceDialogRef.display({
          title: type ? "批量改价" : "改价",
        });
        this.revisePriceTitle = type ? "批量改价" : "改价";
        //查询上次改价记录数据
        const getGoodsId = this.revisePriceTitle = type ? 0 : list[0].goods_id
        API_goods.getChangePriceLog(getGoodsId).then(res => {
          if (res) {
            this.revisePriceForm.price_type=res.shop_price.price_type
            this.revisePriceForm.symbol=res.shop_price.symbol
            this.revisePriceForm.ratio=res.shop_price.ratio
          }
        })
      } else {
        this.dialogShopShow1 = true;
        this.pa.shop_id = this.$store.state.user.shop.shop_id;
        this.shop_id = this.$store.state.user.shop.shop_id;
        if (this.batchModifyPrice) {
          this.idsList = list.map((item) => item.id);
          this.goodsIds = list.map((item) => item.goods_id);
        } else {
          this.idsList = list;
          this.goodsIds = list;
        }
      }
    },

    /** 商品改价 */
    revisePrice () {
      if (!this.revisePriceList.length && this.cou === 1) {
        this.$message.error("请选择要改价的商品");
        return false;
      } else {
        let isd = this.revisePriceList;

        const params = {
          ...this.revisePriceForm,
        };

        if (this.revisePriceTitle === "改价" || this.cou === 1) {
          params.choose_type = 1;
          params.ids = isd;
        } else {
          params.choose_type = 2;
          params.ids = [];
          params.param = {
            ...this.params,
          };
        }

        if (this.revisePriceTitle === "改价") {
          params.is_batch = 1;
        } else {
          params.is_batch = 0;
        }

        return new Promise((resolve) => {
          this.$confirm("确认修改此价格?", "提示", { type: "warning" }).then(
            () => {
              API_goods.nrevisePrice(params).then(() => {
                this.$message.success("改价成功");
                // this.isRevisePrice = false;
                this.modifyPriceDialogRef.dismiss();
                this.GET_GoodsList().then(
                  () => {
                    this.loadReviseTask();
                    resolve(true);
                  },
                  () => {
                    resolve(false);
                  }
                );
              });
            },
            () => resolve(false)
          );
        });
      }
    },

    /** 库存边界限制 */
    checkQuantity (value) {
      if (!value && value !== 0) {
        this.$message.error("库存不能为空");
      } else if (!RegExp.integer.test(value) && parseInt(value) !== 0) {
        this.$message.error("请输入整数");
      } else if (!(parseInt(value) >= 0 && parseInt(value) <= 99999999)) {
        this.$message.error("请输入0 - 99999999之间的正整数");
      }
    },

    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_GoodsList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_GoodsList();
    },

    search (name) {
      delete this.params.skip;
      sessionStorage.removeItem("shopGoodsListType");
      // delete this.$route.query.type
      this.$route.query.type = 0;
      this.params.category_path = this.categoryParams;
      delete this.params.goods_name;
      delete this.params.goods_sn;

      this.params[this.key_word] = this.keyword;
      if (this.params.self_goods === "2") {
        this.params["goods_source"] = 1;
      } else if (this.params.self_goods === '4') {
        this.params['goods_source'] = 5
      } else {
        this.params["goods_source"] = 0;
      }
      this.params.page_no = 1;
      this.GET_GoodsList();
    },

    /** 切换上下架状态*/
    changeGoodsStatus (val) {
      // delete this.market_enable;
      if (val !== "" && val !== -1) {
        this.params = {
          ...this.params,
          shop_goods_status: val,
        };
      }
      this.GET_GoodsList();
    },

    /** 下架*/
    handleUnderGoods (row) {
      let id = "";
      let arr = [];
      if (row) {
        id = row.id;
        arr.push(row.id);
      }
      if (!row) {
        if (this.idsList.length <= 0) {
          this.$message.error("请勾选商品");
          return;
        }
        id = this.idsList;
        arr = this.idsList;
      }
      this.$prompt("确认下架此商品，输入下架原因", "提示", {
        confirmButtonText: "确 定",
        cancelButtonText: "取 消",
      }).then(({ value }) => {
        API_goods.changeStatus(id, {
          ids: arr,
          status: 0,
          reason: value,
        }).then(() => {
          this.$message.success("下架成功");
          this.GET_GoodsList();
        });
      });
    },

    /** 上架*/
    handleUpGoods (row) {
      let id = "";
      let arr = [];
      if (row) {
        id = row.id;
        arr.push(row.id);
      }
      if (!row) {
        if (this.idsList.length <= 0) {
          this.$message.error("请勾选商品");
          return;
        }
        id = this.idsList;
        arr = this.idsList;
      }
      this.$confirm("确认上架此商品, 是否继续?", "提示", {
        type: "warning",
      }).then(() => {
        API_goods.changeStatus(id, {
          ids: arr,
          status: 1,
        }).then(() => {
          this.$message.success("上架成功");
          this.GET_GoodsList();
        });
      });
    },

    /** 切换商品类型 */
    changeGoodsType (val) {
      delete this.params.goods_type;
      if (val !== "") {
        this.params = {
          ...this.params,
          goods_type: val,
        };
      }
      this.GET_GoodsList();
    },

    /** 切换分组*/
    changeGoodsCateGory (data) {
      delete this.params.shop_cat_path;
      if (data && Array.isArray(data) && data.length !== 0) {
        this.params = {
          ...this.params,
          shop_cat_path: "0|" + data.join("|") + "|",
        };
      }
      this.GET_GoodsList();
    },

    /** 显示下架原因 */
    showUnderReason (row) {
      this.isShowUnderReason = false;
      this.isShowUnderReason = true;
      this.under_reason = row.shop_under_message;
    },

    GET_GoodsList () {
      if (+this.activeName == 3) {
        this.loading = true;
        return this.getGiftGoodsList();
      }
      if (this.activeName === "2" && this.areaSelectData.province !== "") {
        let jd_area = "" + this.areaSelectData.province + "_";
        if (this.areaSelectData.city !== "")
          jd_area += this.areaSelectData.city + "_";
        else jd_area += "0_";
        if (this.areaSelectData.county !== "")
          jd_area += this.areaSelectData.county + "_";
        else jd_area += "0_";
        if (this.areaSelectData.town !== "")
          jd_area += this.areaSelectData.town;
        else jd_area += "0";
        this.params.jd_area = jd_area;
        this.params.jd_area_state = this.areaSelectData.jd_area_state;
        this.quantityStatus = true;
      } else if (this.params.jd_area) {
        delete this.params.jd_area;
        delete this.params.jd_area_state;
        this.quantityStatus = false;
      } else this.quantityStatus = false;
      this.params.is_combo = 0;
      this.params.supplier_enable = 1;
      this.loading = true;
      return API_goods.getShopGoodsList(this.params).then(
        (response) => {
          if (this.params.page_no !== 1 && response.data.length === 0) {
            this.params.page_no -= 1;
            this.GET_GoodsList();
          }
          this.loading = false;
          this.pageData = response;
          // 重新绘制表格
          // this.$refs.tableLayout.$refs.table.doLayout();
        },
        (err) => {
          this.loading = false;
        }
      );
    },

    /** 发布商品*/
    publishGoods () {
      this.showDialog = true;
      // this.$router.push({ name: 'goodPublish', params: { callback: this.GET_GoodsList }})
    },

    /** 跳转回收站*/
    gotoRecycle () {
      this.$router.push({ path: "/goods/recycle-station" });
    },

    /** 编辑商品 isdraft 商品列表1*/
    handleEditGoods (row) {
      this.$router.push({
        name: "shopGoodPublish",
        params: {
          goodsid: row.goods_id,
          isdraft: 1,
          callback: this.GET_GoodsList,
        },
      });
    },

    /** 删除商品 */
    handleDeleteGoods (row) {
      let arr = [];
      let id = "";
      if (row) {
        id = row.id;
        arr.push(row.id);
      }
      if (!row) {
        if (this.idsList.length <= 0) {
          this.$message.error("请勾选商品");
          return;
        }
        id = this.idsList;
        arr = this.idsList;
      }
      this.$confirm("确认删除此商品, 是否继续?", "提示", {
        type: "warning",
      }).then(() => {
        API_goods.deleteGoodsa(arr).then(() => {
          this.GET_GoodsList();
          this.$message.success("删除商品成功！");
        });
      });
    },

    /** 合并数据相同的单元格 */
    arraySpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex < this.goodsStockTitle.length - 3) {
        const _row = this.concactArray[rowIndex][columnIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
    },

    /** 计算要合并列的位置 */
    concactArrayCom (index, item) {
      let _isMerge = false;
      /** 循环列 先循环第一列 若相同则合并 再循环第二列 依次循环 若不相同 则不合并并终止此列循环开始下一列循环 */
      let _currnetRow = [];
      for (let i = 0, _len = this.goodsStockTitle.length - 3; i < _len; i++) {
        if (this.goodsStockTitle[i].prop === "sku_id") {
          i++;
          continue;
        }
        if (
          index > 0 &&
          item[this.goodsStockTitle[i].prop] !==
          this.goodsStockData[index - 1][this.goodsStockTitle[i].prop]
        ) {
          _currnetRow[i] = 1;
          _isMerge = true;
        } else if (index > 0 && !_isMerge) {
          _currnetRow[i] = 0;
          let _count = 1;
          while (this.concactArray[index - _count][i] === 0) {
            _count++;
          }
          this.concactArray[index - _count][i] += 1;
        } else {
          // index === 0
          _currnetRow[i] = 1;
        }
      }
      this.concactArray.push(_currnetRow);
    },

    /** 库存 */
    handleStockGoods (row) {
      this.goodsId = row.goods_id;
      this.goodsStockshow = true;
      setTimeout(() => {
        this.$refs["goodsStockData"].resetFields();
      });
      API_goods.getGoodsStockList(row.goods_id, {}).then((response) => {
        this.goodsStockTitle = this.goodsStockData = [];
        this.goodsStocknums = response.length;
        // 构造待发货字段
        if (response.length > 1) {
          this.$nextTick(() => {
            response.forEach((key) => {
              // 构造待发货字段
              this.$set(
                key,
                "deliver_goods_quantity",
                parseInt(key.quantity) - parseInt(key.enable_quantity)
              );
              // 构造表头
              let _skus = key.spec_list.map((elem) => {
                return {
                  label: elem.spec_name,
                  prop: elem.spec_name,
                };
              });
              this.goodsStockTitle = _skus.concat([
                {
                  label: "规格id",
                  prop: "sku_id",
                },
                {
                  label: "库存",
                  prop: "quantity",
                },
                {
                  label: "待发货数",
                  prop: "deliver_goods_quantity",
                },
              ]);
              // 构造表结构
              let _skuData = key.spec_list.map((elem) => {
                let _map = new Map().set(elem.spec_name, elem.spec_value);
                let obj = Object.create(null);
                for (let [k, v] of _map) {
                  obj[k] = v;
                }
                return obj;
              });
              const _key = {
                sku_id: key.sku_id,
                quantity: key.quantity,
                deliver_goods_quantity: key.deliver_goods_quantity,
              };
              this.goodsStockData.push(Object.assign(_key, ..._skuData));
            });
            // 计算表格合并的位置
            this.concactArray = [];
            this.goodsStockData.forEach((key, index) => {
              this.concactArrayCom(index, key);
            });
          });
        } else {
          response.forEach((key) => {
            // 构造待发货字段
            this.$set(
              key,
              "deliver_goods_quantity",
              parseInt(key.quantity) - parseInt(key.enable_quantity)
            );
          });
          this.goodsStockData = response[0];
        }
      });
    },

    /** 保存库存商品 */
    reserveStockGoods () {
      let _params = [];
      if (Array.isArray(this.goodsStockData)) {
        _params = this.goodsStockData.map((elem) => {
          return {
            quantity_count: elem.quantity,
            sku_id: elem.sku_id,
          };
        });
      } else {
        _params.push({
          quantity_count: this.goodsStockData.quantity,
          sku_id: this.goodsStockData.sku_id,
        });
      }
      const _res = _params.some((key) => {
        return (
          !(
            parseInt(key.quantity_count) >= 0 &&
            parseInt(key.quantity_count) < 99999999
          ) || !/^[0-9]\d*$/.test(key.quantity_count)
        );
      });
      if (_res) {
        this.$message.error("库存须为0 - 99999999之间的整数");
        return;
      }
      API_goods.reserveStockGoods(this.goodsId, _params).then(() => {
        this.goodsStockshow = false;
        this.$message.success("库存商品保存成功");
        this.GET_GoodsList();
      });
    },

    /** 获取分销设置 */
    getDistributionSet () {
      API_goods.getDistributionSet().then((response) => {
        this.distributionSet = response.message;
      });
    },

    /** 返利 获取返利信息*/
    handleRebate (row) {
      setTimeout(() => {
        this.$refs["disRebateData"].resetFields();
      });
      API_goods.getDistributionInfo(row.goods_id).then((response) => {
        this.isShowDisRebate = true;
        this.disRebateData = {
          /** 商品id */
          goods_id: response.goods_id || row.goods_id,

          /** 1级返利 */
          grade1Rebate: response.grade1_rebate,

          /** 2级返利 */
          grade2Rebate: response.grade2_rebate,
        };
      });
    },

    /** 保存分销返利信息 */
    reserveDisSet (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          API_goods.setDistributionInfo(this.disRebateData).then(() => {
            this.isShowDisRebate = false;
            this.$message.success("当前商品分销返利金额修改成功");
          });
        }
      });
    },
    handleSelectionChange (val) {
      if (this.activeName == 3) {
        this.gift.multipleSelection = val;
        return;
      }
      this.selectCarmiTable = val;
      let ids = [];
      let goodsIds = [];
      val.forEach((item) => {
        goodsIds.push(item.goods_id);
      });
      val.forEach((item) => {
        ids.push(item.id);
      });

      this.idsList = ids;
      this.goodsIds = goodsIds;
    },
    handleSortChange ({ prop, order }) {
      if (this.activeName == 3) {
        if (order) {
          this.gift.sort = `${prop}_${order.includes("asc") ? "asc" : "desc"}`;
        } else {
          this.gift.sort = "";
        }
      } else {
        if (order) {
          this.params.sort = `${prop}_${order.includes("asc") ? "asc" : "desc"
            }`;
        } else {
          this.params.sort = "";
        }
      }
      this.GET_GoodsList();
    },
    /**
     * 获取区域数据
     * @param type
     * @param data
     */
    getAreaHandle (type, data = {}) {
      if (type === "province") {
        API_goods.getJDProvince().then((res) => {
          this.provinceList = this.dataHandle(res.data.result);
        });
      } else if (type === "city") {
        API_goods.getJDCity(data.id).then((res) => {
          this.cityList = this.dataHandle(res.data.result);
        });
      } else if (type === "county") {
        API_goods.getJDCounty(data.id).then((res) => {
          this.countyList = this.dataHandle(res.data.result);
        });
      } else if (type === "town") {
        API_goods.getJDTown(data.id).then((res) => {
          this.townList = this.dataHandle(res.data.result);
        });
      }
    },
    dataHandle (data) {
      let returnData = [];
      const keys = Object.keys(data);
      const values = Object.values(data);
      keys.forEach((key, index) => {
        const data = {
          name: key,
          id: values[index],
        };
        returnData.push(data);
      });
      return returnData;
    },
  },
};
</script>
<style type="text/scss" lang="scss" scoped>
@import "@/components/GoodsClassify/styles";
</style>
<style lang="scss" scoped>
.icon-btn {
  border: 1px solid #5463a2;
  padding: 0;
  margin-left: 0;
  box-sizing: border-box;

  .el-icon-circle-plus-outline,
  .el-icon-remove-outline {
    font-size: 22px;
  }
}

.toolbar-search {
  margin-right: 10px;
  width: 20%;
}

.el-row {
  width: 100%;
}

/*下架原因*/
.under-reason {
  color: red;
  cursor: pointer;
}

/deep/ {
  .pop-sku {
    .toolbar {
      display: none;
    }

    .el-dialog__body {
      .el-table {
        border: 1px solid #e5e5e5;
      }
    }

    .el-table__body-wrapper {
      max-height: 400px;
      overflow-y: scroll;
    }
  }
}

.remark-warper {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.gift-remark {
  max-width: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
  position: relative;
  line-height: 1.5em;

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    width: 0;
    height: 1px;
    bottom: 0;
    background-color: #1a43a9;
    transition: 0.36s width;
  }

  &:hover {
    color: #1a43a9;

    &::after {
      width: 100%;
    }
  }
}

.no-remark {
  display: inline-block;
  text-align: center;
  font-size: 12px;
  color: #999;
}

.kind-num {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s;
  color: #1a43a9;
  text-decoration: underline;

  &:active {
    transform: scale(1.2);
  }
}

.kind-img {
  width: 45px;
  height: 45px;
  object-fit: cover;
  margin: 0 auto;
  display: block;
}

.key-cover {
  width: 200px;
  height: auto;
}

.kind-list {
  width: 100%;
  height: 500px;
}
</style>
